<template>
	<div>
		<Header :title="isEdit ? '编辑场地' : '新增场地'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError
				@finish="onSubmit" :labelCol="{span: 4}" :wrapperCol="{span: 14}">
				<a-form-item label="场地名称" name="name" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input v-model:value="modelRef.name" :disabled="isSee" placeholder="请输入"></a-input>
				</a-form-item>
				
				<a-form-item label="场地编码" name="code" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input v-model:value="modelRef.code" :maxlength="4" :disabled="isSee" placeholder="请输入4位编码"></a-input>
				</a-form-item>

				<a-form-item label="演出城市" name="cityIds" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-form-item-rest>
						<selectCity v-model:value="modelRef.cityIds"></selectCity>
					</a-form-item-rest>
					<!-- <addressSelect v-model:value="modelRef.cityIds" :level="3" :disabled="isSee"></addressSelect> -->
				</a-form-item>
				
				<a-form-item label="详细地址" name="address" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="modelRef.address" :disabled="isSee" style="width:350px;" placeholder="请输入详细地址"></a-input>
				</a-form-item>
				
				<a-form-item label="地址经纬度" name="coordinate" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="modelRef.coordinate" :disabled="isSee" style="width:200px;" placeholder="请填写地址坐标"></a-input>
					<a-button type="primary" @click="onOpenWin">坐标拾取器</a-button>
				</a-form-item>

				<a-form-item label="所属影院" name="cinemaId">
					<cinema v-model:value="modelRef.cinemaId" :disabled="isSee"></cinema>
				</a-form-item>
				
				<a-form-item label="所属影厅" name="hallId">
					<hall v-model:value="modelRef.hallId" :cinemaId="modelRef.cinemaId" :disabled="isSee"></hall>
				</a-form-item>

				<a-form-item label="场地图片" name="images">
					<a-button type="primary" @click="onAddImg" :disabled="isSee">新增图片</a-button>
					<a-form-item-rest>
						<a-table rowKey="rowIndex" :columns="columns" :dataSource="list" :pagination="false">
							<template #bodyCell="{ column, record, index }">
								<template v-if="column.key === 'img'">
									<a-upload list-type="picture-card"
										accept="image/*"
										:show-upload-list="false"
										action="/admin/ajaxUpload.do"
										:disabled="isSee"
										@change="(file) => onUploadChange(file, index)"
									>
										<div v-if="!record.url">
											<Icon icon="PlusOutlined"></Icon>
											<div style="margin-top: 8px">Upload</div>
										</div>
										<div v-else>
											<a-image :src="record.url" style="width: 100px;height: 100px;"></a-image>
										</div>
									</a-upload>
									<span style="color: #aaa;">建议宽高比：1:1</span>
								</template>
								<template v-if="column.key === 'sort'">
									<a-input-number v-model:value="record.sort" :min="0" :precision="0" :disabled="isSee" placeholder="请输入"></a-input-number>
								</template>
								<template v-if="column.key === 'action'">
									<a-button type="link" size="small" :disabled="isSee" @click="onDelete(index)">删除</a-button>
								</template>
							</template>
						</a-table>
					</a-form-item-rest>
				</a-form-item>

				<a-form-item label="状态" name="isDisabled">
					<a-radio-group v-model:value="modelRef.isDisabled" :disabled="isSee">
						<a-radio :value="0">启用</a-radio>
						<a-radio :value="1">禁用</a-radio>
					</a-radio-group>
				</a-form-item>

				<a-row>
					<a-col :offset="6">
						<a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</a-col>
				</a-row>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import { Icon } from "@/components/icon/icon.js";
	import Header from '@/components/header/header.vue';
	// import addressSelect from '@/components/addressSelect/index.vue';
	import selectCity from '@/components/select/selectCity/index.vue';
	import cinema from '@/views/performance/components/cinema/cinema.vue';
	import hall from '@/views/performance/components/cinema/hall.vue';
	import {
		getPerformSiteDetail,
		savePerformSite,
		updatePerformSite
	} from "@/service/modules/perform";
	export default {
		components: {
			Icon,
			Header,
			selectCity,
			cinema,
			hall
		},
		props: {
			isEdit: {
				type: Boolean,
				default: false
			},
			isSee: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				loading: false,
				modelRef: {
					cityIds: [],
					isDisabled: 0
				},
				list: [],
				columns: [{
					title: '图片',
					key: 'img'
				}, {
					title: '排序',
					key: 'sort'
				}, {
					title: '操作',
					key: 'action'
				}],
			}
		},
		created() {
			if (this.id) {
				this.getData();
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				postData.images = [];
				let isUpload = true;
				this.list.forEach(item => {
					if (!item.url) {
						this.isUpload = false;
					} else {
						postData.images.push({
							url: item.url,
							sort: item.sort || 0
						})
					}
				})
				if (this.list.length === 0 || !isUpload) {
					this.$message.warn('图片未上传完整，无法提交')
					return;
				}
				postData.cityId = postData.cityIds[0];
				delete postData.cityIds;
				postData.longitude = postData.coordinate.split(',')[1];
				postData.latitude = postData.coordinate.split(',')[0];
				delete postData.coordinate;
				this.loading = true;
				try {
					let ret;
					if (this.isEdit && this.id) {
						ret = await updatePerformSite(postData);
					} else {
						ret = await savePerformSite(postData);
					}
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.onBack(true);
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getPerformSiteDetail({
						id: this.id,
					})
					this.loading = false;
					if (ret.code === 200) {
						ret.data.coordinate = ret.data.latitude + ',' + ret.data.longitude;
						ret.data.cityIds = [ret.data.cityId];
						this.list = ret.data.images.map((item, index) => {
							return {
								rowIndex: index,
								url: item.url,
								sort: item.sort
							}
						});
						if (!ret.data.cinemaId) {
							delete ret.data.cinemaId;
						}
						if (!ret.data.hallId) {
							delete ret.data.hallId;
						}
						this.modelRef = ret.data;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onUploadChange(info, index) {
				if (info.file.status === 'done') {
					info.fileList.forEach(file => {
						if (file.response) {
							this.list[index].url = file.response.data.imgUrl;
						}
					});
				}
			},
			onAddImg() {
				this.list.push({
					url: '',
					sort: '',
					rowIndex: this.list.length
				})
			},
			onDelete(index) {
				this.list.splice(index, 1);
			},
			onOpenWin() {
				window.open('https://lbs.qq.com/getPoint/');
			},
		}
	}
</script>

<style>
</style>